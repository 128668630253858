  <template>
  <div style="height:100%;">
    <a-layout style="height:100%;">
      <a-layout-content style="height:100%;">
        <div
          class="yj-conten"
          style="min-height: calc(100% - 48px);background-color:#fff;min-width:calc(100% - 32px);width:max-content"
        >
          <a-card :bordered="false"
            style="min-width:min-content; min-height:100%;"
            :tab-list="tabListNoTitle"
            :active-tab-key="noTitleKey"
            @tabChange="key => onTabChange(key, 'noTitleKey')"
          >
          </a-card>
          <!--<applysetting v-if="noTitleKey==0"></applysetting>-->
          <introduction v-if="noTitleKey==1"></introduction>
          <agreement v-if="noTitleKey==2"></agreement>
          <Partnertemplate v-if="noTitleKey==3"></Partnertemplate>

        </div>
      </a-layout-content>
    </a-layout>
  </div>
</template>

<script>
//import applysetting from './components/applysetting' //合伙人申请配置组件
import introduction from './components/introduction'
import agreement from './components/agreement'
import Partnertemplate from './components/template'
export default {
  name: "PartnerModule_PartnerSetting",
  data() {
    return {
       tabListNoTitle: [
        {
          key: "1",
          tab: "招募介绍"
        },
        {
          key: "2",
          tab: "招募协议"
        },{
          key:'3',
          tab:'招募海报'
        }
      ],
      noTitleKey: "1",
    };
  },
  components: {
    //applysetting,
    introduction,
    agreement,
    Partnertemplate
  },
  methods: {
    onTabChange(key, type) {
      this[type] = key;
      this.noTitleKey = key;
    },
  },
  mounted() {

  },
  beforeMount() {
  
  },
  created: function() {

  }
};
</script>
<style>
</style>
 