<template>
    <a-form layout="horizontal" style="padding:10px 00px;height:auto;width:1400px;">
        <a-form-item label="开启团队招募协议"
                     style="margin-bottom:10px;"
                     :label-col="formItemLayout.labelCol"
                     :wrapper-col="formItemLayout.wrapperCol">
            <a-form layout="inline">
                <a-form-item label>
                    <a-switch :checked="SummarySetting.IsNeed" @change="SummaryCheckChange()">
                        <a-icon slot="checkedChildren" type="check" />
                        <a-icon slot="unCheckedChildren" type="close" />
                    </a-switch>
                </a-form-item>
            </a-form>
        </a-form-item>

        <a-form-item label="团队招募协议"
                     style="margin-bottom:20px;"
                     :label-col="formItemLayout.labelCol"
                     :wrapper-col="formItemLayout.wrapperCol"
                     v-if="SummarySetting.IsNeed==true">
            <Rich v-bind:value.sync="SummarySetting.Summay"></Rich>
        </a-form-item>
        <a-form-item></a-form-item>
        <a-form-item></a-form-item>
        <a-form-item>
            <a-button style="margin-left:14rem;"
                      type="primary"
                      @click="SummarySettingConfiguration()"
                      :loading="loading">保存</a-button>
        </a-form-item>
    </a-form>
</template>
<script>
    import Rich from "@/components/RichEditor.vue";
    import http from "@/Plugin/Http.js";
    import util from "@/Plugin/util.js";
    export default {
        name: "agreement",
        data() {
            return {
                type: 2,
                SummarySetting: [],
                loading: false,
                formItemLayout: {
                    labelCol: {
                        xs: { span: 24 },
                        sm: { span: 3 }
                    },
                    wrapperCol: {
                        xs: { span: 24 },
                        sm: { span: 20 }
                    }
                }
            };
        },
        props: {
            Rich
        },
        methods: {
            //方法
            SummaryCheckChange() {
                this.SummarySetting.IsNeed = !this.SummarySetting.IsNeed;
            },
            GetSummarySetting() {
                var self = this;
                var op = {
                    url: "/PartnerModule/PartnerSetting/Summary_Setting_Search",
                    data: {
                        type: self.type
                    },
                    OnSuccess: function (res) {
                        if (res.data != "" && res.data != null && res.data != undefined) {
                            self.SummarySetting = res.data;
                        } else {
                            self.SummarySetting = {
                                ID: -1,
                                Summay: "",
                                IsNeed: false,
                                CreateTime: util.formatDate(new Date()),
                                SummaryType: "团队招募协议"
                            };
                        }
                    }
                };
                http.Post(op);
            },

            SummarySettingConfiguration() {
                var self = this;
                self.SummarySetting.CreateTime = util.formatDate(new Date());

                if (self.SummarySetting.IsNeed) {
                    // if (self.SummarySetting.Summay == "") {
                    if (self.SummarySetting.Summay == " " || self.SummarySetting.Summay == null || self.SummarySetting.Summay == "") {
                        this.$message.error(
                            "请输入" + self.SummarySetting.SummaryType + "内容"
                        );
                        return;
                    }
                }



                self.loading = true;
                var op = {
                    url: "/PartnerModule/PartnerSetting/SaveChangesSummarySetting",
                    data: self.SummarySetting,
                    OnSuccess: function () {
                        self.$message.success("设置保存成功");
                        self.GetSummarySetting();
                        self.loading = false;
                    }
                };
                http.Post(op);
            }
        },
        computed: {
            //计算属性
        },
        watch: {
            //侦听属性
        },
        components: {
            //组件
            Rich
        },
        beforeCreate: function () {
            //生命周期，创建前
        },
        created: function () {
            //生命周期，创建完成
        },
        beforeMount: function () {
            //生命周期，组装前
        },
        mounted: function () {
            //生命周期，组装完成
            this.GetSummarySetting();
        },
        beforeUpdate: function () {
            //生命周期，data更新前
        },
        updated: function () {
            //生命周期，data更新完成
        },
        beforeDestroy: function () {
            //生命周期，销毁前
        },
        destroyed: function () {
            //生命周期，销毁完成
        }
    };
</script>