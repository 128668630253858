<template>
    <a-form layout="horizontal" style="padding:10px 00px;height:auto;width:1400px;">
        <a-form-item label="选择模板"
                     style="margin-bottom:24px;"
                     :label-col="formItemLayout.labelCol"
                     :wrapper-col="formItemLayout.wrapperCol">
            <div class="yj-storecon-template" :data="TemplateList">
                <div v-for="item in TemplateList"
                     v-bind:key="item.ID"
                     style="width:auto"
                     @click="chooseTemplate(item.ID)"
                     :class="item.IsChoose?'active':''">
                    <img :src="item.BackgroudImg" />
                </div>
            </div>
        </a-form-item>
        <a-form-item>
            <a-button v-if="isShowButton"
                      style="margin-left:14rem;"
                      type="primary"
                      @click="SavePartnerTemplate()"
                      :loading="loading">保存</a-button>
        </a-form-item>
    </a-form>
</template>
<style>
     .yj-storecon-template{height:auto;max-width:100%;}
        .yj-storecon-template > div {
            position: relative;
            height:260px;
            min-width:150px;
            width: auto;
            padding: 6px;
            overflow: hidden;
            text-align: center;
            flex-shrink: 0;
            font-size: 0.66rem;
        }
</style>
<script>
    import http from "@/Plugin/Http.js";
    import util from "@/Plugin/util.js";

    export default {
        name: "Partnertemplate",
        data() {
            return {
                TemplateList: [],
                CurrentTemplate: {
                    TemplateID: ''
                },
                loading: false,
                isShowButton: false,
                formItemLayout: {
                    labelCol: {
                        xs: { span: 24 },
                        sm: { span: 3 }
                    },
                    wrapperCol: {
                        xs: { span: 24 },
                        sm: { span: 20 }
                    }
                }
            };
        },

        methods: {
            chooseTemplate: function (data) {
                var self = this;
                //   self.CurrentTemplate.TemplateUrl =
                //     "http://mptest.ay001.net/CommonProduct/ShopHome/IndexTemplate?CustomID=dsi201907290012&IntroducerID=uat20200429000006&templateid=" +
                //     data;
                if (data == self.CurrentTemplate.TemplateID) return;
                   self.TemplateList.forEach(element => {
                    if (element.ID == data) {
                        element.IsChoose = true;
                        self.CurrentTemplate.TemplateID = data;
                    } else {
                        element.IsChoose = false;
                    }
                   });
            },
            //获取合伙人招募模板
            GetTemplateList() {
                var self = this;
                var op = {
                    url: "/PartnerModule/PartnerSetting/GetPartnerTemplateList",
                    OnSuccess: function (res) {
                        if (res.data.length > 0) {
                            self.isShowButton = true;
                            self.TemplateList = res.data;
                        } else {
                            self.isShowButton = false;
                            self.TemplateList = [];
                        }
                    }
                };
                http.Post(op);
            },
            //保存模板编辑
            SavePartnerTemplate() {
                var self = this;
                self.loading = true;
                if (util.isBlank(self.CurrentTemplate.TemplateID)) {
                  self.TemplateList.forEach(element => {
                    if (element.IsChoose) {
                        self.CurrentTemplate.TemplateID = element.ID;
                    }
                   });
                }
                var op = {
                    url: "/PartnerModule/PartnerSetting/SavePartnerTemplate",
                    data: {
                        TemplateID: self.CurrentTemplate.TemplateID
                    },
                    OnSuccess: function () {
                        self.$message.success("设置保存成功");
                        self.loading = false;
                    }
                };
                http.Post(op);
                self.loading = false;

            }
        },
        computed: {
            //计算属性
        },
        watch: {
            //侦听属性
        },
        components: {},
        beforeCreate: function () {
            //生命周期，创建前
        },
        created: function () {
            //生命周期，创建完成
        },
        beforeMount: function () {
            //生命周期，组装前
        },
        mounted: function () {
            //生命周期，组装完成
            this.GetTemplateList();
        },
        beforeUpdate: function () {
            //生命周期，data更新前
        },
        updated: function () {
            //生命周期，data更新完成
        },
        beforeDestroy: function () {
            //生命周期，销毁前
        },
        destroyed: function () {
            //生命周期，销毁完成
        }
    };
</script>